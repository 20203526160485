<template>
  <div>
    <b-row>

      <b-col
        md="12"
        xl="3"
      >
        <b-form-group label-for="name">
          <validation-provider
            #default="{ errors }"
            name="CardNumber"
            rules="required"
          >
            <label>ادخل رقم البطاقة</label>

            <b-form-input
              id="helperInput"
              v-model="CardNumber"
              :state="errors.length > 0 ? false : null"
            />

            <small
              v-if="!beneficaryWithCardNumber"
              class="text-danger"
            >ادخل رقم بطاقة صحيح</small>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        xl="2"
      >
        <b-form-group label-for="name">
          <b-button
            style="margin-top:25px"
            variant="gr"
            @click="getBeneficiaryByCardNumber(CardNumber)"
          >
            <span class="align-middle"> بحث </span>
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <label> جار جلب البيانات</label>
      </div>

    </div>
    <form-wizard
      v-if="noData"

      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="حفظ"
      back-button-text="السابق"
      next-button-text="التالي"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- accoint detailstab :before-change="validationForm" -->
      <tab-content
        disabled
        title="معلومات الزيارة "
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              md="3"
              
            >
              <b-form-group label-for="phone">
                <label>تاريخ الزيارة</label>
                <flat-pickr
                  v-model="visit.visitModel.visit_date"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="  مكان الزيارة"
                label-for="place"
              >
                <validation-provider
                  #default="{ errors }"
                  name="place"
                  rules="required"
                >
                  <b-form-input
                    v-model="visit.visitModel.visit_place"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3" v-if="visit.visitModel.number">
              <b-form-group
                label=" كود الزيارة "
                label-for="number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="number"
                  
                >
                  <b-form-input
                    id="c-number"
                    v-model="visit.visitModel.number"
                    type="string"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="family"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label-for="familyNatural"
                >
                  <label> طبيعة العائلة</label>

                  <v-select
                    v-model="visit.visitModel.family_status_id"
                    :options="FamilyStatus"
                    label="name"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="FormType"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label-for="FormType"
                >
                  <label> نوع الاستمارة</label>

                  <v-select
                    v-model="visit.visitModel.form_type"
                    :options="FormType"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="اسم الاب"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="father_name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="visit.visitModel.father_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="اسم الام"
                label-for="mother_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="mother_name"
                  rules="required"
                >
                  <b-form-input
                    id="mother_name"
                    v-model="visit.visitModel.mother_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="عدد الاطفال"
                label-for="childrens_count"
              >
                <validation-provider
                  #default="{ errors }"
                  name="childrens_count"
                  vid="childrens_count"
                  rules="required"
                >
                  <b-form-input
                    id="childrens_count"
                    v-model="visit.visitModel.childrens_count"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="عدد المسنين "
                label-for="elders_count"
              >
                <validation-provider
                  #default="{ errors }"
                  name="elders_count"
                  rules="required"
                >
                  <b-form-input
                    id="c-elders_count"
                    v-model="visit.visitModel.elders_count"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">

              <b-form-group
                label="رقم الاتصال "
                label-for="contact"
              >
                <validation-provider
                  #default="{ errors }"
                  name="contact"
                  rules="required"
                >
                  <b-form-tags
                    id="c-contact"
                    v-model="visit.visitModel.contact"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="مكان الاقامة الاصلي "
                label-for="native_place"
              >
                <validation-provider
                  #default="{ errors }"
                  name="native_place"
                  rules="required"
                >
                  <b-form-input
                    id="c-native_place"
                    v-model="visit.visitModel.native_place"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="نوع الاقامة "
                label-for="residence_type_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="residence_type_id"
                  rules="required"
                >

                  <v-select
                    v-model="visit.visitModel.residence_type_id"
                    :reduce="(val) => val.id"
                    :options="ResidenceTypes"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="تفاصيل الاقامة "
                label-for="residence_description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="residence_description"
                  rules="required"
                >
                  <b-form-input
                    id="c-residence_description"
                    v-model="visit.visitModel.residence_description"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="idea">
                <validation-provider
                  #default="{ errors }"
                  name="idea"
                  rules="required"
                >
                  <label>
                    هل بوجد لدى احد افراد الاسرة فكرة واعدة لاعداد مشروع يساعد
                    من دخل الاسرة</label>
                  <v-select
                    v-model="visit.visitModel.idea"
                    :reduce="(val) => val.id"
                    :options="ideaOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="visit.visitModel.idea == 1"
              md="6"
            >
              <b-form-group
                label="نوع الفكرة "
                label-for="idea_type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="idea_type"
                  rules="required"
                >
                  <v-select
                    v-model="visit.visitModel.idea_type"
                    :reduce="(val) => val.id"
                    :options="ideaTypes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="visit.visitModel.idea == 1"
              md="6"
            >
              <b-form-group
                label="تفاصيل الفكرة "
                label-for="idea_description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="idea_description"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="visit.visitModel.idea_description"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="4"
            >
              <b-form-group label-for="notes">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-volunteer_notes"
                  v-model="visit.visitModel.volunteer_notes"
                  placeholder="الملاحظات"
                  rows="2"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab :before-change="validationFormInfo"     -->
      <tab-content
        title="نقاط الضعف  "
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <div
            v-for="(item, i) in visit.weaknessModel"
            :key="i"
          >
            <b-row>
              <b-col
                md="6"
                xl="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="weakness"
                  rules="required"
                >
                  <b-form-group
                    label-for="gender"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>  الفرد</label>
                    <v-select
                      v-model="item.person_id"

                      :reduce="(val) => val.id"
                      :options="optionPerson"
                      label="name"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="weakness"
                  :rules="{ subOptionRequired: { optionsWeak: optionsWeak } }"
                >

                  <b-form-group
                    label-for="gender"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> نقطة الضعف</label>
                    <treeselect
                      v-model="item.weakness_id"
                      :multiple="true"

                      :options="optionsWeak"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="4"
              >
                <b-form-group label-for="notes">
                  <label>الملاحظات</label>
                  <b-form-textarea
                    id="textarea-volunteer_notes"
                    v-model="item.notes"
                    placeholder="الملاحظات"
                    rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="i != 0"
                md="2"
                xl="3"
              >
                <b-button
                  variant="gr"
                  style="width: 73px !important;
    margin-top: 38px;
"
                  @click="removeweaknessModel(i)"
                >
                  <span class="align-middle"> إلغاء </span>
                </b-button>
              </b-col>
            </b-row>
            <hr>
          </div>

          <b-row>
            <b-col xl="4">
              <b-button
                variant="outline-primary"
                @click="addweaknessModel"
              >
                <span class="align-middle"> إضافة نقطة ضعف اخرى </span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address :before-change="validationFormAddress" -->
      <tab-content
        title="التحويلات"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <div
            v-for="(item, i) in visit.referralModel"
            :key="i"
          >
            <b-row>
              <b-col
                md="6"
                xl="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="weakness"
                  rules="required"
                >
                  <b-form-group
                    label-for="gender"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>  الفرد</label>
                    <v-select
                      v-model="item.person_id"

                      :reduce="(val) => val.id"
                      :options="optionPerson"
                      label="name"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="4"
              >
                <b-form-group label-for="notes">
                  <label>الملاحظات</label>
                  <b-form-textarea
                    id="textarea-volunteer_notes"
                    v-model="item.notes"
                    placeholder="الملاحظات"
                    rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="service"
                  rules="required"
                >
                  <b-form-group
                    label-for="service"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الخدمة</label>
                    <v-select
                      v-model="item.main_service_id"
                      :reduce="(val) => val.value"
                      :options="optionService"
                      @input="getSubService(item.main_service_id)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="documentType"
                  rules="required"
                >
                  <b-form-group
                    label-for="cardNumber"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الخدمة الفرعية </label>
                    <v-select
                      v-model="item.proposal_service_id"
                      :reduce="(val) => val.value"
                      :options="optionSubServices"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                v-if="i != 0"
                md="2"
                xl="3"
              >
                <b-button
                  variant="gr"
                  style="    width: 73px !important;
    margin-top: 38px;
"
                  @click="removereferralModel(i)"
                >
                  <span class="align-middle"> إلغاء </span>
                </b-button>
              </b-col>

            </b-row>
            <hr>
            <hr>
          </div>
          <b-row>
            <b-col
              xl="4"
              class="mt-5"
            >
              <b-button
                variant="outline-primary"
                @click="addreferralModel"
              >
                <span class="align-middle"> إضافة حالة اخرى </span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- social link -->
      <tab-content
        title="الاسئلة"
        :before-change="validationFormSocial"
      >
        <validation-observer
          ref="socialRules"
          tag="form"
        >
          <b-row v-for="(item, i) in visit.evaluationModel">
            <b-col md="4">
              <b-form-group
                label=""
                label-for="-name"
              >
                <label>{{ item.label }}</label>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="2"
            >
              <validation-provider
                #default="{ errors }"
                name="select"
                rules="required"
              >
                <b-form-group
                  label-for="optionstatus"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الاجابة</label>
                  <v-select
                    v-model="item.status"
                    :reduce="(val) => val.id"
                    :options="optionstatus"
                  />
                </b-form-group>
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              v-if="item.status==1"
              md="6"
              xl="4"
            >
              <b-form-group label-for="notes">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-volunteer_notes"
                  v-model="item.discription"
                  placeholder="الملاحظات"
                  rows="2"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  required, email, max, is_earlier,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTags,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    required,
    Treeselect,
    BButton,
    flatPickr,
    BFormTags,
    BFormTextarea,
    email,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      FamilyStatus: [],
      noData: false,
      loading: false,
      beneficaryWithCardNumber: true,
      optionsWeak: [],
      optionPerson: [],
      ResidenceTypes: [],
      FormType: [
        {
          id: 0,
          label: 'فرد',
        },
        {
          id: 1,
          label: 'عائلة',
        },
      ],
      optionstatus: [
        {
          id: 0,
          label: 'لا',
        },
        {
          id: 1,
          label: 'نعم',
        },
      ],
      optiongender: [
        {
          id: 2,
          label: 'انثى',
        },
        {
          id: 1,
          label: 'ذكر',
        },
      ],
      ideaTypes: [
        {
          id: 0,
          label: 'تجارية',
        },
        {
          id: 1,
          label: 'صناعية',
        },
        {
          id: 2,
          label: 'خدمة',
        },
        {
          id: 3,
          label: 'زراعية',
        },
      ],
      ideaOption: [
        {
          id: 0,
          label: 'لا',
        },
        {
          id: 1,
          label: 'نعم',
        },
      ],
      CardNumber: '',
      visit: {
        visitModel: {
          visit_date: '',
          visit_place: '',
          family_status_id: '',
          father_name: '',
          mother_name: '',
          childrens_count: '',
          elders_count: '',
          number: '',
          form_type: '',
          contact: [],
          native_place: '',
          residence_type_id: '',
          residence_description: '',
          idea_description: '',
          idea: '',
          idea_type: '',
          volunteer_notes: '',
        },
        weaknessModel: [
          {

            notes: '',
            person_id: '',
            weakness_id: [],

          },
        ],
        referralModel: [
          {
            person_id: '',

            main_service_id: '',
            proposal_service_id: '',

            notes: '',
          },
        ],

        evaluationModel: [
          {
            key: 'woman_at_risk',
            label: 'هل يوجد حالات تعنيف للمرأة ؟',
            status: 1,

            discription: '',
          },
          {
            key: 'child_at_risk',
            label: 'هل يوجد حالات تعنيف للاطقال ؟',
            status: 1,

            discription: '',
          },
          {
            key: 'socity_at_fear',
            label: 'هل يوجد حالات خوف من المجتمع  ؟',
            status: 1,

            discription: '',
          },
          {
            key: 'family_brakUpCont',
            label: 'هل يوجد حالات تفكك اسري  ؟',
            status: 1,

            discription: '',
          },
          {
            key: 'risks',
            label: 'هل يوجد حالات خطر واضحة ؟',
            status: 1,

            discription: '',
          },
        ],
      },

      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
      optionService: [],
      optionSubServices: [],
    }
  },
  created() {
    this.getWeak()
    this.getFamilyStatus()
    this.getServiceType()
  },
  methods: {
    removeweaknessModel(index) {
      this.visit.weaknessModel.splice(index, 1)
    },
    getSubService(id) {
      this.optionSubServices = []

      const url = `/api/v1/main_services/${id}/proposal_services`
      this.$http.get(url).then(res => {
        const subServices = res.data.data
        subServices.forEach(el => {
          this.optionSubServices.push({ label: el.name, value: el.id })
        })
      })
    },
    getServiceType() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const proposal_services = res.data.data.main_services
        proposal_services.forEach(el => {
          this.optionService.push({ label: el.name, value: el.id })
        })
      })
    },
    addweaknessModel() {
      this.visit.weaknessModel.push({
        person_id: '',
        weakness_id: [],

        notes: '',
      })
    },
    removereferralModel(index) {
      this.visit.referralModel.splice(index, 1)
    },
    addreferralModel() {
      this.visit.referralModel.push({
        person_id: '',

        main_service_id: '',
        proposal_service_id: '',

        notes: '',
      })
    },
    getBeneficiaryByCardNumber(id) {
      if (id) {
        this.loading = true
        this.$http
          .get(`/api/v1/orv/family-by-card/${id}`)
          .then(res => {
         //   console.log(res.data)
            this.loading = false
            this.beneficaryWithCardNumber = true
            this.noData = true
            this.visit.visitModel.number = res.data.number
            this.visit.visitModel.family_id = res.data.family_id
            this.visit.visitModel.family_status_id = res.data.family_status_id
            this.visit.visitModel.father_name = res.data.father_name
            this.visit.visitModel.mother_name = res.data.mother_name
            this.visit.visitModel.childrens_count = res.data.childrens_count
            this.visit.visitModel.elders_count = res.data.elders_count
            this.visit.visitModel.residence_type_id = res.data.residence_type_id
            this.visit.visitModel.residence_description = res.data.residence_description
          //  console.log(res.data)
            res.data.members.forEach(el => {
              this.optionPerson.push({
                name: el.first_name,
                id: el.id,

              })
            })
          })
          .catch(error => {
            this.loading = false
            this.beneficaryWithCardNumber = false
          // console.log(error.response.data.message);
            //  this.beneficaryWithCardNumber=false
          })
      } else {
        this.$swal({
          title: '',

          text: `ادخل رقم بطاقة 
                 
           `,
          icon: 'error',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    formSubmitted() {
      // console.log("bb",this.visit)
      this.$http.post('/api/v1/orv/existing-visit-transaction', this.visit).then(res => {
        // console.log("bb",res)
        this.$swal({
          title: '',

          text: `تم اضافة  
                 
           `,
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    getFamilyStatus() {
      this.$http.get('/api/v1/family_statuses').then(response => {
        this.FamilyStatus = response.data.data
      })
      this.$http.get('/api/v1/residence_types').then(response => {
        this.ResidenceTypes = response.data.data
      })
      // this.$http.get('/api/v1/weaknesses').then(response => {
      //   // console.log("orv/family-visit-weakness", response);
      //   this.optionsWeak = response.data.data.weaknesses
      // })
    },
    getWeak() {
      this.optionsWeak = []
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const subweak = res.data.data.sub_weaknesses
        const Weak = res.data.data.weaknesses

        Weak.forEach(el => {
          const obj = {}
          obj.children = []
          obj.label = el.name
          obj.id = el.id

          subweak.forEach((ele, index) => {
            if (el.id == ele.parent_id) {
              obj.children.push({ label: subweak[index].name, id: ele.id })
            }
          })
          this.optionsWeak.push(obj)
        })
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
  <style lang="scss">
  label {
      color: #5e5873;
      font-size: 0.90rem !important;
  }
  @import "@core/scss/vue/libs/vue-wizard.scss";
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
